import React, { useMemo } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/footer/Footer";
import Box from "@mui/material/Box";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import LabelIcon from "@mui/icons-material/Label";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import { useListing } from "../../hooks/useListing";
import dayjs from "dayjs";

const getRooms = (hotelBookingData, listing) => {
  if (listing?.rooms.length && Object.keys(hotelBookingData.rooms).length) {
    return listing?.rooms
      .filter((room) =>
        Object.keys(hotelBookingData.rooms).includes(room.id.toString())
      )
      .map((room) => ({
        ...room,
        quantity: hotelBookingData.rooms[room.id],
        current_price: getRoomPrice(room),
      }));
  }
  return [];
};

const getRoomPrice = (room) => {
  if (room.prices.length > 0) {
    const validPrices = room.prices.find((price) => {
      const now = dayjs();
      return dayjs(price.from_date) <= now && dayjs(price.to_date) >= now;
    });
    return validPrices ? validPrices.price : room.default_price;
  }
  return room.default_price;
};

// export default ViewBookings;
const BookingDetail = () => {
  const Navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const booked_property = JSON.parse(
    decodeURIComponent(searchParams.get("hotel"))
  );
  const options = JSON.parse(decodeURIComponent(searchParams.get("options")));
  const c = searchParams.get("c");
  const dates = JSON.parse(decodeURIComponent(searchParams.get("dates")));
  const Root = styled("div")(({ theme }) => ({
    width: "100%",
    ...theme.typography.body2,
    "& > :not(style) + :not(style)": {
      marginTop: theme.spacing(2),
    },
  }));
  const { loggedinUser } = useSelector((state) => state.getLoggedInUser);
  const { hotelBookingData } = useSelector((state) => state.hotelBookingData);
  const numberOfNights =
    hotelBookingData.dates.length > 1
      ? dayjs(hotelBookingData.dates[1], "DD-MM-YYYY").diff(
          dayjs(hotelBookingData.dates[0], "DD-MM-YYYY"),
          "day"
        )
      : 0;

  const { data: listing } = useListing(booked_property);

  const selectedRooms = useMemo(
    () => getRooms(hotelBookingData, listing),
    [hotelBookingData, listing]
  );
  console.log(hotelBookingData);
  const totalPrice =
    selectedRooms.reduce(
      (result, room) => result + room.current_price * room.quantity,
      0
    ) * numberOfNights;

  const steps = ["Your Selection", "Your Details", "Final Step"];
  const api = process.env.REACT_APP_BACKEND_URL_LOCAL;

  let src;
  if (listing && listing.images.length) {
    src = listing.images[0].path;
  } else {
    src = null;
  }

  let CorrectCheckIn = "";
  let CorrectCheckOut = "";

  if (dates && dates.length === 2) {
    let startingDate = dates[0];
    const [startday, startmonth, startyear] = startingDate
      .split("-")
      .map(Number);
    CorrectCheckIn = startyear + "-" + startmonth + "-" + startday;
    startingDate = new Date(startyear, startmonth - 1, startday); // Note: month is 0-indexed in JavaScript
    let endingDate = dates[1];
    const [endday, endmonth, endyear] = endingDate.split("-").map(Number);
    CorrectCheckOut = endyear + "-" + endmonth + "-" + endday;
    endingDate = new Date(endyear, endmonth - 1, endday); // Note: month is 0-indexed in JavaScript
  }

  let Facilities = [];
  // if (booked_property.Facilities) {
  //   Facilities = [...booked_property.Facilities];
  // }

  const HandleBooking = async () => {
    const bookingUrl = `${api}/baw/payment/create_checkout_session`;
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...hotelBookingData,
        listing_id: booked_property,
        token: loggedinUser.user.token,
        url: `${window.location.protocol}//${window.location.host}`,
      }),
    };
    try {
      const response = await fetch(bookingUrl, requestOptions);
      const data = await response.json();
      console.log(data);
      if (response.ok) {
        window.location = data.url;
      } else {
        alert("Booking Failed");
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      <Navbar />
      <div
        className="container my-4"
        style={{
          maxWidth: "1200px",
          margin: "0 auto",
          padding: "20px",
          backgroundColor: "#fff",
          boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
        }}
      >
        <div className="row">
          <div className="py-2">
            <div className="">
              <div className="card-body">
                <div className="text-center">
                  <h5 className="mb-3 card-title fw-bold text-dark">
                    Thank you for purchasing
                  </h5>
                </div>
                <div>
                  Back to <Link to={"/"}>Home Page</Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BookingDetail;

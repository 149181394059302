import React, { useMemo } from "react";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/footer/Footer";
import Box from "@mui/material/Box";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import LabelIcon from "@mui/icons-material/Label";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";
import { useListing } from "../../hooks/useListing";
import dayjs from "dayjs";

const getRooms = (hotelBookingData, listing) => {
  if (listing?.rooms.length && Object.keys(hotelBookingData.rooms).length) {
    return listing?.rooms
      .filter((room) =>
        Object.keys(hotelBookingData.rooms).includes(room.id.toString())
      )
      .map((room) => ({
        ...room,
        quantity: hotelBookingData.rooms[room.id],
        current_price: getRoomPrice(room),
      }));
  }
  return [];
};

const getRoomPrice = (room) => {
  if (room.prices.length > 0) {
    const validPrices = room.prices.find((price) => {
      const now = dayjs();
      return dayjs(price.from_date) <= now && dayjs(price.to_date) >= now;
    });
    return validPrices ? validPrices.price : room.default_price;
  }
  return room.default_price;
};

// export default ViewBookings;
const ViewBookings = () => {
  const Navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const booked_property = JSON.parse(
    decodeURIComponent(searchParams.get("hotel"))
  );
  const options = JSON.parse(decodeURIComponent(searchParams.get("options")));
  const c = searchParams.get("c");
  const dates = JSON.parse(decodeURIComponent(searchParams.get("dates")));
  const Root = styled("div")(({ theme }) => ({
    width: "100%",
    ...theme.typography.body2,
    "& > :not(style) + :not(style)": {
      marginTop: theme.spacing(2),
    },
  }));
  const { loggedinUser } = useSelector((state) => state.getLoggedInUser);
  const { hotelBookingData } = useSelector((state) => state.hotelBookingData);
  const numberOfNights =
    hotelBookingData.dates.length > 1
      ? dayjs(hotelBookingData.dates[1], "DD-MM-YYYY").diff(
          dayjs(hotelBookingData.dates[0], "DD-MM-YYYY"),
          "day"
        )
      : 0;

  const { data: listing } = useListing(booked_property);

  const selectedRooms = useMemo(
    () => getRooms(hotelBookingData, listing),
    [hotelBookingData, listing]
  );
  console.log(hotelBookingData);
  const totalPrice =
    selectedRooms.reduce(
      (result, room) => result + room.current_price * room.quantity,
      0
    ) * numberOfNights;

  const steps = ["Your Selection", "Your Details", "Final Step"];
  const api = process.env.REACT_APP_BACKEND_URL_LOCAL;

  let src;
  if (listing && listing.images.length) {
    src = listing.images[0].path;
  } else {
    src = null;
  }

  let CorrectCheckIn = "";
  let CorrectCheckOut = "";

  if (dates && dates.length === 2) {
    let startingDate = dates[0];
    const [startday, startmonth, startyear] = startingDate
      .split("-")
      .map(Number);
    CorrectCheckIn = startyear + "-" + startmonth + "-" + startday;
    startingDate = new Date(startyear, startmonth - 1, startday); // Note: month is 0-indexed in JavaScript
    let endingDate = dates[1];
    const [endday, endmonth, endyear] = endingDate.split("-").map(Number);
    CorrectCheckOut = endyear + "-" + endmonth + "-" + endday;
    endingDate = new Date(endyear, endmonth - 1, endday); // Note: month is 0-indexed in JavaScript
  }

  let Facilities = [];
  // if (booked_property.Facilities) {
  //   Facilities = [...booked_property.Facilities];
  // }

  const HandleBooking = async () => {
    const bookingUrl = `${api}/baw/payment/create_checkout_session`;
    const requestOptions = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        ...hotelBookingData,
        listing_id: booked_property,
        token: loggedinUser.user.token,
        url: `${window.location.protocol}//${window.location.host}`,
      }),
    };
    try {
      const response = await fetch(bookingUrl, requestOptions);
      const data = await response.json();
      console.log(data);
      if (response.ok) {
        window.location = data.url;
      } else {
        alert("Booking Failed");
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  return (
    <>
      <Navbar />
      <div
        className="container "
        style={{
          maxWidth: "1200px",
          margin: "0 auto",
          padding: "20px",
          backgroundColor: "#fff",
          boxShadow: "0 0 20px rgba(0, 0, 0, 0.1)",
          borderRadius: "10px",
        }}
      >
        <Box sx={{ width: "100%", my: 2 }}>
          <Stepper activeStep={1}>
            {steps.map((label, index) => {
              const labelProps = {};
              return (
                <Step key={label}>
                  <StepLabel {...labelProps}>{label}</StepLabel>
                </Step>
              );
            })}
          </Stepper>
        </Box>
        <div className="row">
          <div className="col-md-4">
            <div className="py-2">
              <div className="">
                <div className="card-body">
                  <div className="text-center">
                    <h5 className="mb-3 card-title fw-bold text-dark">
                      Your Booking Details
                    </h5>
                  </div>
                  <div>
                    <div className="d-flex justify-content-between">
                      <div className="d-flex my-1">
                        <i className="fas fa-calendar-alt mt-1"></i>
                        <span className="mx-1">
                          {hotelBookingData.dates.length > 0
                            ? hotelBookingData.dates[0]
                            : ""}
                        </span>
                      </div>
                      <div className="d-flex my-1">
                        <i className="fas fa-calendar-alt mt-1"></i>
                        <span className="mx-1">
                          {hotelBookingData.dates.length > 1
                            ? hotelBookingData.dates[1]
                            : ""}
                        </span>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between my-1">
                      <i className="fas fa-moon"></i>
                      <span>{numberOfNights} Nights</span>
                    </div>
                    <div className="d-flex justify-content-between my-1">
                      <i className="fas fa-users"></i>
                      <span>
                        {hotelBookingData.adult} Adults,{" "}
                        {hotelBookingData.children} Child
                      </span>
                    </div>
                  </div>
                  <hr />
                  <div>
                    <small className="fw-bold text-dark">You selected:</small>
                    <div className="text-muted small">
                      {selectedRooms.map((room, index) => (
                        <>
                          {index > 0 ? " - " : " "}{" "}
                          {`${room.quantity}x ${room.room_name}`}
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-8">
            <div className="py-2 d-flex flex-column">
              <div className="justify-content-center">
                <div className=" shadow-0 ">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-3 col-xl-3 col-sm-12">
                        <div className="h-100 bg-image hover-zoom ripple rounded ripple-surface">
                          <img
                            src={src}
                            style={{ objectFit: "cover" }}
                            className="w-100 h-100"
                          />
                          <div className="hover-overlay">
                            <div
                              className="mask"
                              style={{
                                backgroundColor: "rgba(253, 253, 253, 0.15)",
                              }}
                            ></div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-9 col-lg-9 col-xl-9">
                        <h5>
                          {listing?.name} {listing?.location}
                        </h5>
                        {/* <Box
                          className="justify-content-start"
                          sx={{
                            width: 200,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          <Rating
                            name="hover-feedback"
                            readOnly
                            value={
                                : 4
                            }
                            precision={1}
                            getLabelText={getLabelText}
                            emptyIcon={
                              <StarIcon
                                style={{ opacity: 0.55 }}
                                fontSize="inherit"
                              />
                            }
                          />
                          {
                            <Box className="ms-3" sx={{ mb: 1, fontSize: 17 }}>
                              {
                                labels[
                                    : 4
                                ]
                              }
                            </Box>
                          }
                        </Box> */}
                        <div
                          className="mt-1 mb-0 text-muted"
                          style={{ fontSize: "12px" }}
                        >
                          {Facilities.length > 0 && (
                            <>
                              {Facilities.slice(3, 7).map((facility, index) => (
                                <React.Fragment key={index}>
                                  <span>{facility}</span>
                                  {index !==
                                    Facilities.slice(3, 7).length - 1 && (
                                    <span className="text-primary"> • </span>
                                  )}
                                </React.Fragment>
                              ))}
                            </>
                          )}
                          <br />
                        </div>
                        {/* <div
                          className="mb-1 text-muted small"
                          style={{ fontSize: "12px" }}
                        >
                          {Facilities.length > 0 && (
                            <>
                              {Facilities.slice(0, 3).map((facility, index) => (
                                <React.Fragment key={index}>
                                  <span>{facility}</span>
                                  {index !==
                                    Facilities.slice(0, 3).length - 1 && (
                                    <span className="text-primary"> • </span>
                                  )}
                                </React.Fragment>
                              ))}
                            </>
                          )}
                          <br />
                        </div> */}
                        <p className="w-100 fst-italic text-muted">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: listing?.description,
                            }}
                          ></div>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-1">
          <hr />
        </div>
        {/* <div className="row ">
          <div className="col-md-12">
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <h5 className="text-dark fw-bold my-3">Rooms To Be Booked</h5>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="row mb-3">
                    {options.singleRoom > 0 && (
                      <>
                        <div className="col-md-4">
                          <LabelIcon color="primary" className="mx-2" />
                          <span>Single Room(s):</span>
                        </div>
                        <div className="col-md-8 justify-content-end">
                          {booked_property.featured ? (
                            <>
                              <span className="fw-bold">
                                {booked_property.singleRoomsArray.map(
                                  (room, index) =>
                                    `Room No: ${room.number}${
                                      index ===
                                      booked_property.singleRoomsArray.length -
                                        1
                                        ? ""
                                        : ", "
                                    }`
                                )}
                              </span>
                            </>
                          ) : (
                            <>
                              <span className="fw-bold">
                                {singleRoomsArray.map(
                                  (room, index) =>
                                    `Room No: ${room.number}${
                                      index === singleRoomsArray.length - 1
                                        ? ""
                                        : ", "
                                    }`
                                )}
                              </span>
                            </>
                          )}
                        </div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> */}
        {/* <div className="row mt-1">
          <hr />
        </div> */}
        <div className="row">
          <div className="">
            <div className="py-2">
              <div className="justify-content-center">
                <div className="">
                  <div className="card-body">
                    <div className="row text-start mb-4">
                      <h5 className="mb-0 fw-bold text-dark">
                        Your Price Summary
                      </h5>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <Root>
                          <Divider className=" text-muted fs-6">
                            Rooms Booked
                          </Divider>
                        </Root>
                        {selectedRooms.map((room, index) => (
                          <div className="d-flex justify-content-between align-items-center">
                            <span>
                              <LabelIcon color="primary" className="mx-2" />
                              {room.room_name}
                            </span>
                            <span className="fw-bold">
                              {`${room.quantity}x  $S${room.current_price}`}
                            </span>
                          </div>
                        ))}
                        {/* {options.singleRoom > 0 ? (
                          <div className="d-flex justify-content-between align-items-center">
                            <span>
                              <LabelIcon color="primary" className="mx-2" />
                              Single Room
                            </span>
                            <span className="fw-bold">
                              {`${options.singleRoom}x  ${booked_property.SingleRoomPrice}`}
                            </span>
                          </div>
                        ) : null}*/}
                      </div>
                    </div>

                    {/* {additionalCharges && (
                      <div className="row mt-3">
                        <div className="col-md-12">
                          <Root>
                            <Divider className=" text-muted fs-6">
                              Additonal Charges
                            </Divider>
                          </Root>
                          <div className="d-flex justify-content-between align-items-center">
                            <span>
                              <LabelIcon color="primary" className="mx-2" />
                              {additionalChargesDescription}:
                            </span>
                            <span className="fw-bold">
                              ${additionalChargesPrice}
                            </span>
                          </div>
                        </div>
                      </div>
                    )} */}
                    {/* <div className="row mt-3">
                      <div className="col-md-12">
                        <Root>
                          <Divider className=" text-muted fs-6">
                            Tax Details
                          </Divider>
                        </Root>
                        <div className="d-flex justify-content-between align-items-center">
                          <span>
                            <LabelIcon color="primary" className="mx-2" />
                            {taxDescription}:
                          </span>
                          <span className="fw-bold">${taxPrice}</span>
                        </div>
                      </div>
                    </div> */}
                    <div
                      className="row mt-4 p-4 rounded-1"
                      style={{ backgroundColor: "#ebf3ff" }}
                    >
                      <div className="col-md-12">
                        <div className="border-bottom pb-2">
                          <div className="d-flex justify-content-between align-items-center text-white p-2">
                            <h5 className="text-dark fw-bold fs-2">
                              Total Price
                            </h5>
                            <div>
                              <span className="fw-bold ms-1 text-dark fs-4">
                                $S{totalPrice}
                              </span>
                              <br />
                              {/* <small className="text-dark ms-1">
                                +PKR ${taxPrice + additionalChargesPrice} taxes
                                and charges
                              </small> */}
                            </div>
                          </div>
                        </div>
                        <div className="text-end">
                          <button
                            className="btn mt-3 btn-primary btn-sm fw-bold text-light fs-6 "
                            onClick={HandleBooking}
                          >
                            Book
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ViewBookings;

import "./hotel.css";
import Navbar from "../../Components/Navbar/Navbar";
import Footer from "../../Components/footer/Footer";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosNewIcon from "@mui/icons-material/ArrowForwardIos";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import RestaurantMenuIcon from "@mui/icons-material/RestaurantMenu";
import { useState, useEffect } from "react";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import BedIcon from "@mui/icons-material/Bed";
import Diversity1TwoToneIcon from "@mui/icons-material/Diversity1TwoTone";
import DoneIcon from "@mui/icons-material/Done";
import { useMediaQuery } from "@mui/material";
import TextField from "@mui/material/TextField";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CircularProgress from "@mui/material/CircularProgress";
import PeopleIcon from "@mui/icons-material/People";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useListing } from "../../hooks/useListing";
import { ListingTypeKey } from "../../helpers/constants";

import "leaflet/dist/leaflet.css";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import L from "leaflet";

export const icon = L.icon({ iconUrl: "/images/marker-icon.png" });

const Hotel = () => {
  const dispatch = useDispatch();
  const Navigate = useNavigate();
  const location = useLocation();
  const { listingId } = useParams();
  const { data: listing } = useListing(listingId);
  const searchParams = new URLSearchParams(location.search);
  const selected_hotel = JSON.parse(
    decodeURIComponent(searchParams.get("hotel"))
  );
  const options = JSON.parse(decodeURIComponent(searchParams.get("options")));
  const c = searchParams.get("c");
  const dates = JSON.parse(decodeURIComponent(searchParams.get("dates")));
  // const { options } = useSelector((state) => state.searchOption);
  // const { c } = useSelector((state) => state.searchVehicle);
  // const { dates } = useSelector((state) => state.searchDate);
  const { success_State } = useSelector((state) => state.successState);

  const { activePath } = useSelector((state) => state.activePath);
  // const { selected_hotel } = useSelector((state) => state.getSelectedHotel);
  const { hotelBookingData } = useSelector((state) => state.hotelBookingData);
  const api = process.env.REACT_APP_BACKEND_URL_LOCAL;
  const [option, setOption] = useState(hotelBookingData);

  console.log("hotelBookingData");
  console.log(hotelBookingData);

  let Facilities = [];
  let date = new Date();
  const now = dayjs();

  const availableRooms =
    listing?.rooms.filter(
      (room) =>
        !room.availables.length ||
        room.availables.find(
          (available) =>
            now >= dayjs(available.available_from_date) &&
            now <= dayjs(available.available_to_date)
        )
    ) || [];

  console.log("availableRooms");
  console.log(availableRooms);

  const handleOptionChange = (e) => {
    dispatch({
      type: "hotelBookingData",
      payload: {
        ...hotelBookingData,
        [e.target.name]: parseInt(e.target.value),
      },
    });
  };

  const startDate =
    Array.isArray(hotelBookingData.dates) && hotelBookingData.dates.length > 0
      ? dayjs(hotelBookingData.dates[0], "DD-MM-YYYY")
      : dayjs(`${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`);
  const endDate =
    Array.isArray(hotelBookingData.dates) && hotelBookingData.dates.length > 1
      ? dayjs(hotelBookingData.dates[1], "DD-MM-YYYY")
      : dayjs(
          `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate() + 2}`
        );
  // const id = location.pathname.split("/")[2];
  const [slideNumber, setSlideNumber] = useState(0);
  const [open, setOpen] = useState(false);
  const [rooms, setRooms] = useState([]);
  const [singleRoomError, setSingleRoomError] = useState(false);
  const [twinRoomError, setTwinRoomError] = useState(false);
  const [familyRoomError, setFamilyRoomError] = useState(false);
  const [loading, setLoading] = useState(false);

  let roomArray = [];
  let singleRoomsArray = [];
  let twinRoomsArray = [];
  let familyRoomsArray = [];

  const handleOpen = (i) => {
    setSlideNumber(i);
    setOpen(true);
  };

  const handleMove = (direction) => {
    let newSlideNumber;

    if (direction === "l") {
      newSlideNumber =
        slideNumber === 0 ? listing.images.length - 1 : slideNumber - 1;
    } else {
      newSlideNumber =
        slideNumber === listing.images.length - 1 ? 0 : slideNumber + 1;
    }

    setSlideNumber(newSlideNumber);
  };

  //card responsiveness for mobile screen
  const isXtraSmallScreen = useMediaQuery("(max-width:400px)");

  // To handle see more button
  let [limit, setLimit] = useState(6);
  const handleSeemore = () => {
    limit === Facilities.length ? setLimit(6) : setLimit(Facilities.length);
  };

  const Reload = () => {
    // dispatch({
    //   type: "SUCCESS",
    //   payload: false,
    // });
    // setSuccess(false);
    setLoading(false);
    setRooms([]);
  };
  const CancelSelection = () => {
    setOption({
      adult: 1,
      children: 0,
      singleRoom: 1,
      twinRoom: 0,
      familyRoom: 0,
    });
    dispatch({
      type: "SET_OPTION",
      payload: {
        adult: 1,
        children: 0,
        singleRoom: 1,
        twinRoom: 0,
        familyRoom: 0,
      },
    });
    dispatch({
      type: "SET_DATE",
      payload: [],
    });
    setSingleRoomError(false);
    setTwinRoomError(false);
    setFamilyRoomError(false);
    // setSuccess(false);
    dispatch({
      type: "SUCCESS",
      payload: false,
    });
    setLoading(false);
    setRooms([]);
  };

  const HandleBook = () => {
    if (success_State) {
      let updatedHotel = {
        ...selected_hotel,
        Total_Price,
        roomArray,
        singleRoomsArray,
        twinRoomsArray,
        familyRoomsArray,
        featured: true,
      };
      dispatch({
        type: "SET_BOOKED_PROPERTY",
        payload: updatedHotel,
      });
    } else {
      dispatch({
        type: "SET_BOOKED_PROPERTY",
        payload: selected_hotel,
      });
      Navigate(
        `/hotel/hotellist/bookingdetails?hotel=${encodeURIComponent(
          JSON.stringify(selected_hotel)
        )}&dates=${encodeURIComponent(
          JSON.stringify(dates)
        )}&options=${encodeURIComponent(JSON.stringify(options))}&c=${c}`
      );
    }
  };

  const CheckConditions = (e) => {
    handleRoomsRequest(e);
  };

  const handleRoomsRequest = async (event) => {
    event.preventDefault();
    Navigate(`/hotel/hotellist/bookingdetails?hotel=${listingId}`);
  };

  const Total_Price = 0;

  const ConfirmSelectedHotel = (e) => {
    e.preventDefault();
    let updatedHotel = {
      ...selected_hotel,
      Total_Price,
      roomArray,
      featured: true,
    };
    dispatch({
      type: "SET_BOOKED_PROPERTY",
      payload: updatedHotel,
    });
    Navigate("/bookingdetails");
  };

  // To disable scroll when modal is open
  useEffect(() => {
    const disableScroll = () => {
      if (open) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "visible";
      }
    };
    window.addEventListener("scroll", disableScroll);
    return () => window.removeEventListener("scroll", disableScroll);
  }, [open]);

  return (
    <>
      <div
        class="modal fade"
        id="staticBackdrop"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg ">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="staticBackdropLabel">
                {listing?.location}
              </h1>
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="modal"
                onClick={CancelSelection}
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-3 " style={{ backgroundColor: "#fff" }}>
              <div className="row my-1">
                <h2 className="my-2">Enter Check-In Check-Out Dates :</h2>
                <div className="col-md-6 mb-2 col-sm-12">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        className="w-100"
                        label="Check-In Date"
                        value={startDate}
                        name="startDate"
                        onChange={(newValue) => {
                          dispatch({
                            type: "hotelBookingData",
                            payload:
                              hotelBookingData?.dates?.length > 1
                                ? {
                                    ...hotelBookingData,
                                    dates: [
                                      newValue.format("DD-MM-YYYY"),
                                      hotelBookingData.dates[1],
                                    ],
                                  }
                                : {
                                    ...hotelBookingData,
                                    dates: [
                                      newValue.format("DD-MM-YYYY"),
                                      null,
                                    ],
                                  },
                          });
                        }}
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
                <div className="col-md-6 mb-3 col-sm-12 ">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["DatePicker"]}>
                      <DatePicker
                        className="w-100"
                        label="Check-Out Date"
                        value={endDate}
                        onChange={(newValue) => {
                          dispatch({
                            type: "hotelBookingData",
                            payload:
                              hotelBookingData?.dates?.length > 0
                                ? {
                                    ...hotelBookingData,
                                    dates: [
                                      hotelBookingData.dates[0],
                                      newValue.format("DD-MM-YYYY"),
                                    ],
                                  }
                                : {
                                    ...hotelBookingData,
                                    dates: [
                                      null,
                                      newValue.format("DD-MM-YYYY"),
                                    ],
                                  },
                          });
                        }}
                        name="endDate"
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
              <div className="row my-1">
                <h2 className="my-2">Enter No Of Adults and Childrens</h2>
                <div
                  className={` ${
                    activePath === "hotelAndParking" ? "col-md-4" : "col-md-6"
                  } mb-3 col-sm-12`}
                >
                  <TextField
                    id="filled-basic"
                    label="Adults"
                    variant="outlined"
                    value={hotelBookingData.adult}
                    onChange={handleOptionChange}
                    name="adult"
                    type="number"
                    fullWidth
                  />
                </div>
                <div
                  className={`${
                    activePath === "hotelAndParking" ? "col-md-4" : "col-md-6"
                  } mb-3 col-sm-12`}
                >
                  <TextField
                    id="filled-basic"
                    label="Childrens"
                    variant="outlined"
                    value={hotelBookingData.children}
                    onChange={handleOptionChange}
                    name="children"
                    type="number"
                    fullWidth
                  />
                </div>
              </div>
              <div className="row my-1 gap-4">
                <h2 className="my-2">Rooms:</h2>
                {listing &&
                  availableRooms.map((room) => (
                    <div
                      className="col-md-4 mb-3 col-sm-6 border border-slate-900 rounded-lg p-2"
                      style={{ borderRadius: 8, position: "relative" }}
                    >
                      <div
                        style={{
                          width: "80%",
                          whiteSpace: "nowrap",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {room.room_name}
                      </div>
                      <div style={{ position: "absolute", right: 2, top: 6 }}>
                        {room.capacity} <PeopleIcon sx={{ pb: 0.5 }} />
                      </div>
                      <div style={{ height: 140 }}>
                        {room.images.length > 0 && (
                          <img
                            src={room.images[0].path}
                            style={{
                              height: 120,
                              width: "auto",
                              marginBottom: 12,
                            }}
                          />
                        )}
                      </div>
                      <TextField
                        id="filled-basic"
                        label={room.room_name}
                        // onChange={handleOptionChange}
                        onChange={(e) =>
                          dispatch({
                            type: "hotelBookingData",
                            payload: {
                              ...hotelBookingData,
                              rooms: {
                                ...hotelBookingData.rooms,
                                [e.target.name]: parseInt(e.target.value),
                              },
                            },
                          })
                        }
                        value={hotelBookingData.rooms?.[room.id] ?? ""}
                        variant="outlined"
                        name={room.id}
                        type="number"
                        fullWidth
                      />
                    </div>
                  ))}
              </div>
              {(singleRoomError || twinRoomError || familyRoomError) && (
                <>
                  <div className="row my-1">
                    <small className="text-dnager">
                      If you want to select other room types and proceed.
                    </small>
                  </div>
                  <div className="row ">
                    <div className="col-md-2">
                      <button
                        className="btn btn-success btn-sm rounded-8"
                        onClick={Reload}
                      >
                        Try Again
                      </button>
                    </div>
                  </div>
                </>
              )}
              {success_State && (
                <>
                  <div className="row my-1">
                    <span className="fs-3 text-warning">Note!</span>
                    <small className="text-dnager">
                      The rooms you requested are availble for booking.If you
                      want to process this booking please click{" "}
                      <span className="fst-italic fw-bold">
                        Proceed Booking{" "}
                      </span>
                      button Below.
                    </small>
                    <small className="text-dnager">
                      If you want to select other room types and proceed.Click
                      <span className="fst-italic fw-bold">
                        Try Again{" "}
                      </span>{" "}
                      button Below.
                    </small>
                  </div>
                  <div className="row ">
                    <div className="col-md-2">
                      <button
                        className="btn btn-secondary btn-sm rounded-8"
                        onClick={Reload}
                      >
                        Try Again
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
            <div class="modal-footer">
              {loading && (
                <>
                  <CircularProgress />
                </>
              )}
              {!success_State && !loading && (
                <>
                  <button
                    type="button"
                    // onClick={RequestRooms}
                    data-bs-dismiss="modal"
                    onClick={() =>
                      dispatch({
                        type: "hotelBookingData",
                        payload: {},
                      })
                    }
                    class="btn btn-lg btn-secondary"
                  >
                    Clear Form
                  </button>
                  <button
                    type="button"
                    // onClick={RequestRooms}
                    data-bs-dismiss="modal"
                    onClick={CheckConditions}
                    class="btn btn-lg btn-secondary"
                  >
                    Confirm Booking
                  </button>
                </>
              )}
              {success_State && (
                <button
                  type="button"
                  class="btn btn-success btn-lg"
                  data-bs-dismiss="modal"
                  onClick={ConfirmSelectedHotel}
                >
                  Proceed Booking
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div style={{ overflow: "hidden" }}>
        <Navbar list={false} />
        {false ? (
          "loading"
        ) : (
          <div className="hotelContainer continer-fluid">
            {open && (
              <div className="slider">
                <div className="sliderWrapper">
                  <CloseIcon
                    className="close"
                    onClick={() => {
                      setOpen(false);
                      document.body.style.overflow = "visible";
                    }}
                  />
                  <ArrowBackIosNewIcon
                    className="arrow fs-1"
                    onClick={() => handleMove("l")}
                  />
                  <img
                    src={
                      listing.images.length
                        ? listing.images[slideNumber].path
                        : ""
                    }
                    style={{ objectFit: "cover" }}
                    alt=""
                    className="sliderImg"
                  />
                  <ArrowForwardIosNewIcon
                    className="arrow fs-1"
                    onClick={() => handleMove("r")}
                  />
                </div>
              </div>
            )}
            <div className="hotelWrapper px-3">
              <div className="row p-1">
                <div className="col-md-9">
                  <h1 className="hotelTitle">{listing?.location}</h1>
                  <div className="hotelAddress my-1">
                    <LocationOnIcon className="fs-6" />
                    <span className="">{listing?.country}</span>
                    <span className="text-primary fw-bold">
                      {listing?.city}
                    </span>
                  </div>
                  {/* <span className="hotelDistance my-1">
                    Excellent location – 30m from center
                  </span>
                  <span className="hotelPriceHighlight d-block my-1">
                    Book a stay over $30 at this property and get a free airport
                    taxi
                  </span> */}
                </div>
                <div className="col-md-3 col-sm-6 col-12 text-start mt-1">
                  {!dates || dates?.length === 0 ? (
                    <button
                      type="button"
                      class="btn btn-primary"
                      onClick={Reload}
                      data-bs-toggle="modal"
                      data-bs-target="#staticBackdrop"
                    >
                      Select Dates and Rooms
                    </button>
                  ) : (
                    <button
                      className={`btn btn-primary ${
                        isXtraSmallScreen ? "my-1" : ""
                      }`}
                      onClick={HandleBook}
                      // disabled={dates.length === 0}
                    >
                      Reserve or Book Now!
                    </button>
                  )}
                </div>
              </div>
              <div className="hotelImages">
                {listing?.images.length > 0 &&
                  listing.images.map((photo, i) => {
                    return (
                      <>
                        <div className="hotelImgWrapper" key={i}>
                          <img
                            onClick={() => {
                              if (!isXtraSmallScreen) {
                                window.scrollTo(20, 20);
                                handleOpen(i);
                              }
                            }}
                            src={photo.path}
                            alt="hotel_img"
                            className="hotelImg"
                          />
                        </div>
                      </>
                    );
                  })}
              </div>

              <div className="hotelImages">
                {listing?.lat && listing?.lng && (
                  <MapContainer
                    center={[listing.lat, listing.lng]}
                    zoom={13}
                    scrollWheelZoom={false}
                    style={{ height: 400, width: "100%" }}
                  >
                    <TileLayer
                      attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                      url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                    <Marker position={[listing.lat, listing.lng]} icon={icon}>
                      <Popup>{listing.location}</Popup>
                    </Marker>
                  </MapContainer>
                )}
              </div>

              <div className="hotelDetails">
                <div className="hotelDetailsTexts">
                  <h1 className="hotelTitle">{listing?.location}</h1>
                  <p className="fw-lighter  fs-6 lh-base mt-1 fst-italic">
                    <div
                      className="post__content"
                      dangerouslySetInnerHTML={{ __html: listing?.description }}
                    ></div>
                  </p>

                  <div
                    className=" my-2"
                    style={{
                      fontSize: "20px",
                      fontWeight: "700",
                      color: "#000",
                      marginBottom: "10px",
                    }}
                  >
                    {/* <h3 className="my-1">Features:</h3> */}
                    <ul
                      className="mt-2"
                      style={{
                        display: "flex",
                        flexWrap: "wrap",
                        listStyle: "none",
                        margin: "0",
                        padding: "0",
                      }}
                    >
                      {Facilities &&
                        Facilities.slice(0, limit).map((feature, i) => (
                          <li
                            key={feature}
                            className=""
                            style={{
                              backgroundColor: "#ffffff",
                              color: "#555",
                              fontSize: "14px",
                              fontWeight: "700",
                              borderRadius: "20px",
                              padding: "10px 20px",
                              marginRight: "10px",
                              marginBottom: "10px",
                            }}
                          >
                            {feature}
                          </li>
                        ))}
                    </ul>
                  </div>
                  {limit < Facilities.length && (
                    <small
                      onClick={handleSeemore}
                      className="my-auto ms-2 fw-bold text-info cursor_pointer"
                    >
                      See More
                    </small>
                  )}

                  {limit === Facilities.length && (
                    <small
                      onClick={handleSeemore}
                      className="my-auto ms-2 fw-bold text-info cursor_pointer"
                    >
                      See Less
                    </small>
                  )}
                </div>

                <div className="hotelDetailsPrice">
                  <div className="fw-bold">Property Highlights</div>
                  {/* <div className="fw-bold">
                    Perfect for an {selected_hotel.Nights}-night stay!
                  </div> */}

                  <div className="small-font">
                    <div className="field-descriptor">
                      <div className="fw-bold mb-1">Name</div>
                      <div>{listing?.name}</div>
                    </div>
                    {listing?.listing_type && (
                      <div className="field-descriptor">
                        <div className="fw-bold mb-1">Property Type</div>
                        <div>{ListingTypeKey[listing?.listing_type]}</div>
                      </div>
                    )}
                    <div className="field-descriptor">
                      <div className="fw-bold mb-1">Year Build</div>
                      <div>{listing?.year_built}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        <Footer />
      </div>
    </>
  );
};

export default Hotel;

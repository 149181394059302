import { useQuery } from "react-query";
import qs from "query-string";
import { useSelector } from "react-redux";

export const useSearchListingLocation = (params, option = {}) => {
  const { loggedinUser } = useSelector((state) => state.getLoggedInUser);
  const stringified = qs.stringify(params);
  return useQuery(
    ["search_listing", params],
    async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL_LOCAL}/listing/search/?token=${loggedinUser.user.token}&${stringified}`
      );
      if (response.ok) {
        return await response.json();
      } else {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }
    },
    option
  );
};

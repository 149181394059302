import { useQuery } from "react-query";

export const usePlaces = (searchText) => {
  return useQuery(
    ["places", searchText],
    async () => {
      const response = await fetch(
        `${process.env.REACT_APP_BACKEND_URL_LOCAL}/places?location=${searchText}`
      );
      if (response.ok) {
        return await response.json();
      } else {
        return response.text().then((text) => {
          throw new Error(text);
        });
      }
    },
    { enabled: !!searchText }
  );
};

import { createReducer } from "@reduxjs/toolkit";

const initialState = {
  newBookingData: "test - xxx",
};

export const newBookingData = createReducer(initialState, {
  setBookingData: (state, action) => {
    console.log("setBookingData action");
    console.log(action);
    state.newBookingData = action.payload;
  },
});
